<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form" @submit.prevent="validateForm">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  @keydown.enter="focusNext"
                  :label="$t('employeeLevel.name')"
                  dense
                  outlined
                  v-model="item.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="8">
              <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
                {{ $t("employeeLevel.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      item: {
        name: ""
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },
    saveChange() {
      this.$axios.post(`company/add/employee/level`, {
        name: this.item.name
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.$router.push({
            name: "employeeLevel.index",
          })
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
          this.btnLoading = false;
        }
        this.btnLoading = false;
      })

    },
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  height: 100vh;
  padding: 40px 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
  }

}
</style>
